<template >
    <v-app>
        <nav style="background-color:white"
            class="navbar navbar-light navbar-expand-md bg-faded justify-content-center sticky-top ">
            <a href="#" class="d-flex  mr-auto">
                <img class="sss" src="/adnlogo.png" />
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
                <ul class="nav navbar-nav  ml-auto justify-content-center first">


                    <li class="LGactive ml-md-3" style="background-color:#E30525!important;">
                        <a href="https://adn9.hashtagsante.com/
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          "
                            target="_blanc">
                            <b style="color:white">ACCUEIL</b>
                        </a>
                    </li>
                </ul>
            </div>


        </nav>
        <v-container grid-list-md>

            <v-row>
                <v-col align="center" margin-top="50px">
                    <h3 style="color:#DB0204" class="compHead">VIDEO ON DEMAND </h3>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
               
               
                    <img class="aaa" src="/adn2.png" />
                
               
                
                <img class="aaa" src="/adn1.png" />
                      
          
                    <img class="aaa" src="/adn3.png" />
                
            </v-row>
            <v-row>
      <!-- <v-col align="center" cols="12">
                    <img src="/simgalaxy.png" height="60" width="210">
                </v-col> -->


                <v-expansion-panels v-model="panel">

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d"> Pr Louis Monnier</b>
                                            <!-- <hr  width="20%" color="red"> -->
                               <div class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b>  Efficience et précision en diabétologie : symphonie inachevée ?</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/_ToCNzkwCbU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>



                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d"> Pr Eric Renard</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b> L’insulinothérapie dans le diabète de type 1 : un paysage en constante évolution</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/dXYnk-e4Pl0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>




                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d">Pr Louis Monnier</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                      <div class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b> Au-delà des insulines actuelles : des réalisations prometteuses et quelques désillusions</b></span>
                                </v-col>
                               
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/XkfZZXaYD8M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>




                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d"> Pr Asmaa Morjan</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b>  Statut de la vitamine D et L'équilibre diabetique</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/QUfVS74FqzA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>



                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d"> Dr Jean-Philippe Kevorkian</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b>  Diagnostic et traitement d’une ostéite qui complique une plaie de pied diabétique :
le Diabétologue est-il inéluctablement dépendant du Radiologue et du Chirurgien ?</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/dKz8QEf0tic" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>





                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d">Pr Siham El Aziz</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b> Approche clinique et thérapeutique dans la gestion précoce des

complications cardio-rénales du DT2</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/kXGNdAWaDMk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>



                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d">Dr Otman TAZI</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b> L’intensification de l’insulinothérapie: La simplicité au coeur de la prise en charge</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/TryuSysKfzI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>




                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d">Pr Ahmed Anas Guerboub</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b>Diabète Type 2 et Système Nerveux Autonome</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/l6Op6g7bAkI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>


                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <!-- <v-col cols="12" md="2" sm="12" class="" align="left">
                                    <span style="color:#8FB6BC" class="speakername"><b>14:30 > 18:00</b></span>
                                </v-col> -->
                                <v-col cols="8" class="titreline3">
                                            <b style="color:#20729d">Dr El Azrak Nadia</b>
                                            <!-- <hr  width="20%" color="red"> -->
                                             <div  class="titleLine mb-2 "></div> 
                                        </v-col>
                                <v-col cols="12" md="12" sm="12" class="mt-1" align="left">
                                    <span style="color:#2b2525d9" class="speakername"><b>Artériopathie oblitérante des membres inférieurs chez les patients diabétiques</b></span>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="orderFix safariFlex">
                                <v-col cols="12">
                                    <v-card class="iframe-container p-1  darken-2">
                                        <div class="video-responsive">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/JWAsdvttSOw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- <v-row class="ps-3">
                                        <v-col cols="8" class="titreline3">
                                            <b style="color:#20c997">Naoual Wahid </b>
                                            <div class="titleLine mb-2 "></div>
                                        </v-col>
                                        <v-col cols="4" align="right">
                                             <img class="img-responsive " src="../../assets/logo8.png" height="80" width="130"> 
                                        </v-col>
                                    </v-row> -->
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>















                </v-expansion-panels>











            </v-row>

        </v-container>
        <v-footer class="footer">
            <v-col cols="12" md="12" sm="12" align="center" class="mt-5 ">
                <a href="https://hashtagsante.ma/" target="_blanc"><img class="ddd" src="../assets/Asset 6.png"
                        width="460"></a>
            </v-col>
            <v-col cols="12" md="12" sm="12" align="center">
                <p style="color:black">© Copyright 2022 <b>HASHTAGSANTE</b></p>
            </v-col></v-footer>
    </v-app>
</template>
 
<script>
export default {
    data() {
        return {
            overlay: false,
            isImageLoaded: false,
            panel: 0

        }
    },
    props: ['slideshow'],
    components: {
    },
    methods: {
        hideHand() {
            this.animation = false
        },
        onImageLoad() {
            this.isImageLoaded = true
        }

    },

    mounted() {


    },
}
</script>
 
<style>

.compHead {
    margin-top: 40px;

}

.footer {
    margin-top: 150px;
}

.sss {
    resize: both;
    width: 300px;
   
  
}
.aaa {
    position: relative;
    resize: both;
    width: 140px;
    padding: 12px;
    bottom: 10px;
    left: 360px;
}

.ddd {
    resize: both;
    width: 300px;
    height: 150PX;
}

html {
    scroll-behavior: smooth;
}

.first li a {
    color: #000 !important;
    transition: 0.3s;
    font-size: 14px !important;
}

.first li a:hover {
    color: white !important;
    background-color: #0c3d58 !important;
}

.LGactive li a {
    color: white !important;
    background-color: #3e90c0 !important;
    padding: 5px;
}

.LGactive li a:hover {
    background-color: #0c3d58 !important;
}

.logonav {
    width: 250px;
}

.footpara {
    height: 450px !important;
}

.footparashad {
    background-color: rgba(15, 132, 168, 0.877);
    height: 400px !important;
}

.contactAdn {
    color: white !important;
}

a:hover {
    color: #e4a837 !important;
}

.titleDateAs {
    font-size: 15px;
}

.infofooter {
    font-family: "Montserrat", sans-serif;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
    .logonav {
        width: 190px;
    }

    .footpara {
        height: 650px !important;
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .aaa {
    position: relative;
    resize: both;
    width: 100px;
  bottom: 10px;
    left: 35px;
}
    .inscriBtn {
        width: 130px;
    }

    .liveBtn {
        width: 250px;
    }

    .text {
        font-size: 3vw;
        text-justify: distribute;
    }

    .Logofoter {
        width: 250px;
    }

    .logonav {
        width: 150px;
    }

    .footpara {
        height: 645px !important;
    }

    .footparashad {
        height: 650px !important;
    }

    .infofooter {
        font-size: 14px;
        padding-left: 25px !important;
        padding-right: 17px !important;
    }
}

.footer {
    margin-top: 100px;
}

.footText {
    color: white;
    text-align: right;
    font-size: 0.55em;
    color: #fff;
    text-align: right;
    font-family: "Montserrat", sans-serif;
}

v-img.sponsor {
    position: relative;
}

.footTitle {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.titlefootline {
    content: "";
    display: block;
    width: 4.375rem;
    height: 0.25rem;
    background-color: red;
    bottom: 0;
    right: 0;
}

.social {
    position: absolute;
    right: 0px;
    position: fixed;
    width: 50px;
    top: 30%;
    /* z-index: 9; */
}

.navbar {
    margin-bottom: 0px !important;
    z-index: 3 !important;
}

.inscriBtn {
    background-color: #fbb400 !important;
}

.liveBtn {
    background-color: #ff0800 !important;
    color: white !important;
}

.liveBtn {
    color: #ffff !important;
}

.vue-typer .custom.char.typed {
    color: #f19840;
    font-size: 20px;
}

.titleDate {
    font-size: 20px;
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .vue-typer .custom.char.typed {
        font-size: 10px;
    }

    .titleDate {
        font-size: 10px;
    }

    .titleDateAs {
        font-size: 10px;
    }

    .liveBtn {
        margin-bottom: 5px !important;
    }
}











.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.v-card--reveal-first {
    align-items: center;
    top: 120px;
    justify-content: center;
    position: absolute;
    width: 100%;
    font-size: 1.2em
}

.speaker {
    width: 110px;
}

.speakername {
    font-size: 1.5em;
}

.cvtext {
    display: block;
    font-size: 0.8em;
    white-space: pre-line
}

.speakername {
    height: 70%;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.titleLine {
    content: "";
    display: block;
    width: 4.375rem;
    height: .25rem;
    background-color: #5B2075;
    bottom: 0;
    left: 0;
}

.titreline3 {
    color: #205B78;
    font-size: 1.2em
}

.modiName {
    font-size: 0.9em
}

@media (min-width: 800px) and (max-width: 1100px) {
    .cvtext {
        display: block;
        font-size: 1.1vw;
    }

    .v-card--reveal-first {
        font-size: 1em
    }
}

@media (max-width: 770px) {
    .speakername {
        height: 80%;
    }

    .speakername {
        font-size: 1em;
    }
}

@media all and (max-width: 768px) {
    .titreline3 {
        font-size: 0.8em
    }

    .modiName {
        font-size: 0.6em
    }

}

@media (max-width: 767px) {
    .cvtext {
        display: block;
        font-size: 0.9em;
    }
}

@media (max-width: 767px) {
    .cvtext {
        display: block;
        font-size: 0.9em;
    }

    .speaker {
        width: 60px;
    }
}

@media (max-width: 400px) {
    .cvtext {
        display: block;
        font-size: 0.8em;
    }
}

@media (max-width: 360px) {
    .cvtext {
        display: block;
        font-size: 0.7em;
    }

    .speakername {
        height: 60%;
    }
}

.carros {
    position: relative;
}


.pulsate {
    position: absolute;
    top: 50%;
    left: 12.5%;
    animation-name: pulsate;
    animation: pulsate 5s infinite;
    animation-duration: 5s;
}

@media (max-width: 480px) {
    .pulsate {
        left: 45%;
    }
}



















.img-responsive {
    position: relative;
    resize: both;
    width: 400px;
    height: 100px;

}



.img-responsive1 {
    position: relative;
    resize: both;
    width: 400px;
    height: 100px;
    bottom: 30px;
}

.img-responsive2 {
    position: relative;
    resize: both;
    width: 150px;
    height: 100px;
    bottom: 40px;
    left: 190px
}


.iframe-container {
    position: relative;
    overflow: hidden;
    width: 800px;
    margin-top: -10px;
    /* padding-top: 56.25%; */
}

.responsive-iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.bg {
    background: #384C93;
}

.img {
    width: 100%;
    height: 100%;
}

.video {
    position: relative;
    resize: both;
    width: 530px;

}

/* .carros {
    min-height: 460px;
    width: 600px;
} */

/* Large desktop */
@media (min-width: 1200px) {
    .img {
        width: 100%;
        height: 100%;
    }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {


    .img {
        min-width: 100%;
        height: 100%;
    }
}


/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    .img-responsive2 {
        position: relative;
        resize: both;
        width: 150px;
        height: 120px;
        bottom: 20px;
        left: 135px
    }

    .video {
        position: relative;
        resize: both;
        width: 410px;

    }

    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 420px;
        margin-top: -10px;
        /* padding-top: 56.25%; */
    }

    .img {
        min-width: 300px;
        height: auto;
    }

    .carros {
        min-height: 70px;
    }
}

/* Landscape phones and down */
@media (max-width: 480px) {
    .img {
        min-width: 300px;
        height: auto;
    }
}
</style>
 