<template>
  <v-app style="background-image: linear-gradient(to right top, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);">
    <div data-spy="scroll" data-target="#navbar-app" data-offset="0">
      <div id="inscription">
        <inscription class="mt-5" />
      </div>
      <!-- <div class="col-md-12 col-sm-12 text-center mb-3">
        <a href="https://ascrea.ma/"> <img class="aslogo" src="/ascrea.png" alt=""></a>
      </div> -->
    </div>
  </v-app>
</template>

<script>
import inscription from './components/inscription';


export default {
  name: 'App',

  components: {
    inscription,
  },

  data: () => ({
    scrollPosition: null
  }),
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
};
</script>
<style >
html {
  scroll-behavior: smooth;
}

/*.accueil{
  background-image:url('/assets/health.jpg');
  background-repeat:no-repeat;
  background-size:contain;
  background-position:center;
}*/
.first li a {
  color: #000 !important;
  transition: 0.3s;
}

.first li a:hover {
  color: white !important;
  background-color: #D2CAC7 !important
}

.aslogo {
  width: 15%;
}


@media (min-width: 1200px) {}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .inscriBtn {
    width: 130px
  }

  .text {
    font-size: 3vw;
    text-justify: distribute;
  }

  .Logofoter {
    width: 200px
  }

  .aslogo {
    width: 60%;
  }
}



v-img.sponsor {
  position: relative;
}

.titlefootline {
  content: "";
  display: block;
  width: 4.375rem;
  height: .25rem;
  background-color: red;
  bottom: 0;
  right: 0;
}
</style>